import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ElementPanelContain, ElementPanelContainShadow, ElementPanelText, ElementSlideshowContent, ElementSlideshowThumbnail, ElementTitleH1, ElementTitleH2 } from './Element';

import { UtilContent, UtilTranslate, UtilRoute } from './Util';

// Props: debug, ip_address, translation_data, translation_language
function SectionContentDataExpertise(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'notes'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/main/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="panel-section" id="insight">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-header">{UtilTranslate(props.translation_data, 'content_content_header', props.debug)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_insight_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_insight_text', props.debug)}
                            image="panel_insight" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_businessmodel_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_businessmodel_text', props.debug)}
                            image="panel_strategy" />
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_model_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_model_text', props.debug)}
                            image="panel_model" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_machinelearning_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_machinelearning_text', props.debug)}
                            image="panel_machinelearning" />
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_engineering_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_engineering_text', props.debug)}
                            image="panel_engineering" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_cloud_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_cloud_text', props.debug)}
                            image="panel_cloud" />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { /* XS, SM */ }
                            <div className="d-block d-md-none">
                                { content_data !== null &&
                                    <div className="panel-notes-mobile" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                                }
                            </div>

                            { /* MD, LG, XL, XXL */ }
                            <div className="d-none d-md-block">
                                { content_data !== null &&
                                    <div className="panel-notes-desktop" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, translation_data, translation_language
function SectionContentSalesRecommendationAI(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'recommendation'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/main/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="panel-section" id="recommendation">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-header">{UtilTranslate(props.translation_data, 'content_recommendation_header', props.debug)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            { /* XS, SM */ }
                            <div className="d-block d-md-none">
                                <div className="panel-recommendation-header-container-mobile">
                                    <div className="panel-recommendation-header-overlay">
                                        <h2 className="panel-recommendation-header-title-mobile">{UtilTranslate(props.translation_data, 'content_recommendation_header_title', props.debug)}</h2>
                                        <h3 className="panel-recommendation-header-subtitle-mobile">{UtilTranslate(props.translation_data, 'content_recommendation_header_subtitle', props.debug)}</h3>
                                    </div>
                                </div>
                                <div className="panel-recommendation-container-mobile">
                                    <ElementTitleH2 text={UtilTranslate(props.translation_data, 'content_recommendation_title', props.debug)} />

                                    { content_data !== null &&
                                        <div className="panel-text" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'recommendation', props.debug)}}></div>
                                    }
                                    
                                    <img className="panel-recommendation-image" src="/image/panel_salesrecommendation2.jpg" width="100%" alt={UtilTranslate(props.translation_data, 'content_recommendation_header_title', props.debug)}/>
                                </div>
                            </div>
                            
                            { /* MD */ }
                            <div className="d-none d-md-block d-lg-none">
                                <div className="panel-recommendation-header-container-desktop">
                                    <video className="panel-recommendation-header-video" preload="none" autoPlay={true} poster="/video/banner_predict.jpg" muted={true} loop={true}>
                                        <source src="/video/banner_predict.mp4" type="video/mp4" />
                                    </video>
                                    <div className="panel-recommendation-header-overlay">
                                        <h2 className="panel-recommendation-header-title-tablet">{UtilTranslate(props.translation_data, 'content_recommendation_header_title', props.debug)}</h2>
                                        <h3 className="panel-recommendation-header-subtitle-desktop">{UtilTranslate(props.translation_data, 'content_recommendation_header_subtitle', props.debug)}</h3>
                                    </div>
                                </div>
                                <div className="panel-recommendation-container-desktop">
                                    <ElementTitleH2 text={UtilTranslate(props.translation_data, 'content_recommendation_title', props.debug)} />

                                    { content_data !== null &&
                                        <div className="panel-text" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'recommendation', props.debug)}}></div>
                                    }
                                    
                                    <img className="panel-recommendation-image" src="/image/panel_salesrecommendation2.jpg" width="100%" alt={UtilTranslate(props.translation_data, 'content_recommendation_header_title', props.debug)}/>
                                </div>
                            </div>

                            { /* LG, XL, XXL */ }
                            <div className="d-none d-lg-block">
                                <div className="panel-recommendation-header-container-desktop">
                                    <video className="panel-recommendation-header-video" preload="none" autoPlay={true} poster="/video/banner_predict.jpg" muted={true} loop={true}>
                                        <source src="/video/banner_predict.mp4" type="video/mp4" />
                                    </video>
                                    <div className="panel-recommendation-header-overlay">
                                        <h2 className="panel-recommendation-header-title-desktop">{UtilTranslate(props.translation_data, 'content_recommendation_header_title', props.debug)}</h2>
                                        <h3 className="panel-recommendation-header-subtitle-desktop">{UtilTranslate(props.translation_data, 'content_recommendation_header_subtitle', props.debug)}</h3>
                                    </div>
                                </div>
                                <div className="panel-recommendation-container-desktop">
                                    <ElementTitleH2 text={UtilTranslate(props.translation_data, 'content_recommendation_title', props.debug)} />

                                    { content_data !== null &&
                                        <div className="panel-text" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'recommendation', props.debug)}}></div>
                                    }

                                    <img className="panel-recommendation-image" src="/image/panel_recommendation.jpg" width="100%" alt={UtilTranslate(props.translation_data, 'content_recommendation_header_title', props.debug)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ElementPanelContainShadow
                            title={UtilTranslate(props.translation_data, 'content_map_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_map_text', props.debug)}
                            image="panel_map" />
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_combination_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_combination_text', props.debug)}
                            image="panel_combination" />
                    </div>
                    <div className="row">
                        <ElementPanelContainShadow
                            title={UtilTranslate(props.translation_data, 'content_history_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_history_text', props.debug)}
                            image="panel_history" />
                        <ElementPanelContainShadow
                            title={UtilTranslate(props.translation_data, 'content_bundle_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_bundle_text', props.debug)} 
                            image="panel_bundle" />
                    </div>
                    <div className="row">
                        
                        { /*
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_dashboard_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_dashboard_text', props.debug)}
                            image="panel_dashboard" />
                        */ }
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_email_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_email_text', props.debug)}
                            image="panel_email" />
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_integration_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_integration_text', props.debug)}
                            image="panel_integration" />
                    </div>
                    
                    { /*
                    <div className="row">
                        <ElementPanelText
                            title="AI-gedreven bundelverkopen"
                            text="" />
                        <ElementPanelText
                            title=""
                            text="" />
                    </div>
                    <div className="row">
                        <ElementPanelText
                            title="AI-gedreven gelijksoortige klanten helpen"
                            text="Door de deep-learning architectuur leert de AI patronen en kan dit inzetten om de minder actieve klanten beter van dienst te kunnen zijn." />
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_continuousdelivery_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_continuousdelivery_text', props.debug)}
                            image="panel_continuousdelivery" />
                    </div>
                    */ }
                    
                    { /*
                    <div className="row">
                        <ElementPanelText
                            title={UtilTranslate(props.translation_data, 'content_bundle_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_bundle_text', props.debug)} />
                        <ElementPanelText
                            title={UtilTranslate(props.translation_data, 'content_dashboard_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_dashboard_text', props.debug)} />
                    </div>

                    <div className="row">
                        <ElementPanelText
                            title={UtilTranslate(props.translation_data, 'content_deeplearninginsight_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_deeplearninginsight_text', props.debug)} />
                        <ElementPanelText
                            title={UtilTranslate(props.translation_data, 'content_continuousdelivery_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_continuousdelivery_text', props.debug)} />
                    </div>
                    */ }

                    { /*
                    <div className="row">
                        <ElementPanelContain 
                            title="AI-gedreven bundelverkopen"
                            text="text"
                            image="panel_twotower" />
                        <ElementPanelContain 
                            title="AI-gedreven prognoses"
                            text="text"
                            image="panel_twotower" />
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title="CRM-systeem dashboards integratie"
                            text="text"
                            image="panel_twotower" />
                        <ElementPanelContain 
                            title="Software architectuur "
                            text="text"
                            image="panel_twotower" />
                    </div>
                    */ }

                    <div className="row">
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_featureengineering_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_featureengineering_text', props.debug)}
                            image="panel_featureengineering2" />
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_rank_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_rank_text', props.debug)}
                            image="panel_rank2" />
                    </div>
                    <div className="row">
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_twotower_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_twotower_text', props.debug)}
                            image="panel_twotower" />
                        <ElementPanelContain
                            title={UtilTranslate(props.translation_data, 'content_test_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_test_text', props.debug)}
                            image="panel_predictiontable" />
                    </div>
                    <div className="row">
                        <ElementPanelText
                            title={UtilTranslate(props.translation_data, 'content_uptodate_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_uptodate_text', props.debug)} />
                        <ElementPanelText
                            title={UtilTranslate(props.translation_data, 'content_expansion_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_expansion_text', props.debug)}/>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionDisclaimer(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language; 
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'disclaimer'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/main/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="element-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'navigation_disclaimer', props.debug)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { content_data !== null &&
                                <div className="element-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionFooter(props)
{
    return (
        <section className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-title-section">
                            <Link to={'/'+props.translation_language} className="footer-title">Thissen AI<sup className="footer-title-trademark">®</sup></Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'home', null)} className="footer-link">{UtilTranslate(props.translation_data, 'navigation_home', props.debug)}</Link>

                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'disclaimer', null)} className="footer-link">{UtilTranslate(props.translation_data, 'navigation_disclaimer', props.debug)}</Link>

                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)} className="footer-link">{UtilTranslate(props.translation_data, 'navigation_privacy', props.debug)}</Link>

                        <div className="footer-link-seperator">
                            <div className="d-none d-md-block">|</div>
                        </div>
                        
                        <Link to="https://customsoftware.nl" target="_blank" className="footer-link">CustomSoftware.nl</Link>

                        <Link to="https://architectuurvisualisatie.be" target="_blank" className="footer-link">ArchitectuurVisualisatie.be</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr className="footer-line" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-text">
                            {UtilTranslate(props.translation_data, 'footer_content', props.debug)} ©{new Date().getFullYear()} Thissen AI®. KVK 84567791. BTW NL003984459B66. {UtilTranslate(props.translation_data, 'footer_legal', props.debug)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div  className="col-12">
                        <div className="footer-text-location">
                            {UtilTranslate(props.translation_data, 'footer_made', props.debug)} <div className="footer-limburg"></div>. {UtilTranslate(props.translation_data, 'footer_hosted', props.debug)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language
function SectionForm(props)
{
    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_dropdown, form_set_dropdown] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    // Update default dropdown when translation has been loaded
    let debug = props.debug;
    let translation_data = props.translation_data;
    useEffect(() => {
        form_set_dropdown(UtilTranslate(translation_data, 'contactform_dropdown1', debug));
    }, [debug, translation_data]);

    const handleSubmit = (event) => {
        form_set_loading(true);
        
        const post = async () => {
            try
            {
                const data = {
                    name: form_name,
                    email: form_email,
                    dropdown: form_dropdown,
                    description: form_description,
                    terms: form_terms,
                    accept: form_accept
                }

                if (form_phone !== '')
                {
                    Object.assign(data, {
                        'phone': form_phone
                    });
                }

                await axios.post(props.ip_address+'/backend/main/api/form_contact', data);
                
                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();
        
        event.preventDefault();
    }

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';
    
    return (
        <section className="form-section" id="contactform">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-block d-md-none">
                        <div className="form-header">
                            <ElementTitleH2 text={UtilTranslate(props.translation_data, 'contactform_title', props.debug)} />
                            <p className="content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(UtilTranslate(props.translation_data, 'contactform_text', props.debug))}}></p>
                        </div>
                    </div>
                    <div className="col-12 d-none d-md-block">
                        <div className="form-header">
                            <ElementTitleH2 text={UtilTranslate(props.translation_data, 'contactform_title', props.debug)} />
                            <p className="content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(UtilTranslate(props.translation_data, 'contactform_text', props.debug))}}></p>
                        </div>
                    </div>
                </div>

                { form_loading === false && form_sent === false && form_error === false &&
                    <form onSubmit={event => handleSubmit(event)}>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_name"
                                    type="text"
                                    maxLength="128"
                                    value={form_name}
                                    autoComplete="off"
                                    onChange={event => form_set_name(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_name', props.debug)+' *'}
                                    required />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_phone"
                                    type="text"
                                    maxLength="128"
                                    value={form_phone}
                                    autoComplete="off"
                                    onChange={event => form_set_phone(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_phone', props.debug)} />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_email"
                                    type="text"
                                    maxLength="128"
                                    value={form_email}
                                    autoComplete="off"
                                    onChange={event => form_set_email(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_email', props.debug)+' *'} 
                                    required />
                            </div>
                            <div className="col-12">
                                <select
                                    className="form-singleline-dropdown"
                                    name="form_dropdown"
                                    onChange={event => form_set_dropdown(event.target.value)} >
                                    
                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown1', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown1', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown2', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown2', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown3', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown3', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown4', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown4', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown5', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown5', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown6', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown6', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown7', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown7', props.debug)}</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="form-multiline"
                                    name="form_description"
                                    maxLength="4096"
                                    value={form_description}
                                    onChange={event => form_set_description(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_description', props.debug)+' *'} 
                                    required />
                            </div>
                            <div className="col-12">
                                <div className="form-checkbox-container">
                                    <label className={form_terms_class}>
                                        <input
                                            name="form_terms"
                                            type="checkbox"
                                            checked={form_terms}
                                            onChange={event => form_set_terms(event.target.checked)}
                                            required />
                                        <span className="form-text">{UtilTranslate(props.translation_data, 'contactform_terms', props.debug)} <a href={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)} target="_blank" rel="noopener noreferrer">{UtilTranslate(props.translation_data, 'contactform_privacy', props.debug)}</a>. *</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <input
                                    className="form-accept"
                                    name="form_accept"
                                    type="checkbox"
                                    checked={form_accept}
                                    onChange={event => form_set_accept(event.target.checked)} />
                            </div>
                            <div className="col-12">
                                <button onClick = {() => {}} className="form-send"><span>{UtilTranslate(props.translation_data, 'contactform_send', props.debug)}</span></button>
                            </div>
                        </div>
                    </form>
                }

                { form_loading === true &&
                    <div className="form-loading"></div>
                }

                { form_loading === false && form_error === true &&
                    <div className="form-error">{UtilTranslate(props.translation_data, 'contactform_error', props.debug)}</div>
                }

                { form_loading === false && form_error === false && form_sent === true &&
                    <div className="form-thankyou">{UtilTranslate(props.translation_data, 'contactform_complete', props.debug)}</div>
                }
            </div>
        </section>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionNavigation(props)
{
    // Scroll to top
    const location = useLocation();
    useEffect(() => {
        if (location['search'] === '' && location['hash'] === '')
        {
            window.scrollTo({
                top: 0,
                behavior: 'instant',
            });
        }
    }, [location]);

    // Expand (mobile)
    const [expanded, set_expanded] = useState(false);

    // Routes
    const [route_nl, route_set_nl] = useState('/nl-NL');
    const [route_en, route_set_en] = useState('/en-US');

    let debug = props.debug;
    let routes_urls = props.routes_urls;
    let translation_language = props.translation_language;
    useEffect(() => {
        if (translation_language !== null)
        {
            let url_split = location['pathname'].split('/');

            if (typeof url_split[2] === 'undefined')
            {
                // Default
                route_set_nl('/nl-NL');
                route_set_en('/en-US');
            }
            else
            {
                for (let route in routes_urls[translation_language])
                {
                    if (routes_urls[translation_language][route] === url_split[2])
                    {
                        if (debug === true)
                        {
                            console.log(routes_urls['nl-NL'][route]);
                            console.log(routes_urls['en-US'][route]);
                        }

                        let new_route_nl = '/nl-NL/'+routes_urls['nl-NL'][route];
                        let new_route_en = '/en-US/'+routes_urls['en-US'][route];

                        // Add slug
                        if (typeof url_split[3] !== 'undefined')
                        {
                            new_route_nl += '/'+url_split[3];
                            new_route_en += '/'+url_split[3];
                        }

                        route_set_nl(new_route_nl);
                        route_set_en(new_route_en);
                    }
                }
            }
        }
    }, [debug, location, routes_urls, translation_language]);

    // Partner login
    let partnerlogin_data = null;
    
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        partnerlogin_data = [
            ['http://localhost:8001/backend/main/admin', 'Thissen AI®'],
            ['http://localhost:8005/backend/customsoftware/admin', 'CustomSoftware.nl'],
            ['http://localhost:8002/backend/domains/admin', 'VindUwDomeinnaam.nl'],
            ['http://localhost:8003/backend/wrkd/admin', 'WRKD AI'],
            ['http://localhost:8004/backend/levelfinance/admin', 'LevelFinance.nl'],
            ['http://localhost:8006/backend/autoglaslimburg/admin', 'AutoglasLimburg.nl'],
            ['http://localhost:8008/backend/financeteams/admin', 'FinanceTeams.com'],
            ['http://localhost:8007/backend/rechargedsolarpanels/admin', 'Recharged zonnepanelen'],
            ['http://localhost:8009/backend/rechargedbatteries/admin', 'Recharged thuisbatterijen'],
            ['http://localhost:8011/backend/rechargedairconditioners/admin', 'Recharged airconditioners'],
            ['http://localhost:8010/backend/architectuurvisualisatie/admin', 'ArchitectuurVisualisatie.be'],
        ];
    }
    else
    {
        partnerlogin_data = [
            ['https://thissen.ai/backend/main/admin', 'Thissen AI®'],
            ['https://thissen.ai/backend/customsoftware/admin', 'CustomSoftware.nl'],
            ['https://thissen.ai/backend/domains/admin', 'VindUwDomeinnaam.nl'],
            ['https://thissen.ai/backend/wrkd/admin', 'WRKD AI'],
            ['https://thissen.ai/backend/levelfinance/admin', 'LevelFinance.nl'],
            ['https://thissen.ai/backend/autoglaslimburg/admin', 'AutoglasLimburg.nl'],
            ['https://thissen.ai/backend/financeteams/admin', 'FinanceTeams.com'],
            ['https://thissen.ai/backend/rechargedsolarpanels/admin', 'Recharged zonnepanelen'],
            ['https://thissen.ai/backend/rechargedbatteries/admin', 'Recharged thuisbatterijen'],
            ['https://thissen.ai/backend/rechargedairconditioners/admin', 'Recharged airconditioners'],
            ['https://thissen.ai/backend/architectuurvisualisatie/admin', 'ArchitectuurVisualisatie.be'],
        ];
    }
    
    let partnerlogin_items_mobile = [];
    let partnerlogin_items_desktop = [];
    for (let i = 0; i < partnerlogin_data.length; i++)
    {
        partnerlogin_items_mobile.push(
            <div className="navbar-listitem" key={i}>
                <Link to={partnerlogin_data[i][0]} target="_blank" rel="noreferrer" className="navbar-item" onClick={() => set_expanded(false)}>
                    {UtilTranslate(props.translation_data, 'navigation_platform', props.debug)} {partnerlogin_data[i][1]}
                </Link>
            </div>
        );
        
        partnerlogin_items_desktop.push(
            <Link to={partnerlogin_data[i][0]} key={i} target="_blank" rel="noreferrer" className="navbar-item-partnerlogin-dropdown-item" onClick={() => set_expanded(false)}>
                {UtilTranslate(props.translation_data, 'navigation_platform', props.debug)} {partnerlogin_data[i][1]}
            </Link>
        );
    }

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container">
                    <Navbar expanded={expanded} expand="lg" variant="light" className="navbar-container">
                        <Link to={'/'+props.translation_language} className="navbar-title" onClick={() => set_expanded(false)}>Thissen AI<sup className="navbar-title-trademark">®</sup></Link>

                        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="$navbarScroll" onClick={() => set_expanded(expanded ? false : 'expanded')} />
                        <Navbar.Collapse id="navbarScroll" className="navbar-justify-right">
                            <Nav>
                                { /* Multilanguage desktop */ }
                                <div className="d-none d-lg-block">
                                    <div className="navbar-item-multilanguage-container">
                                        { props.translation_language === 'nl-NL' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_nl', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        { props.translation_language === 'en-US' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_en', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        <div className="navbar-item-multilanguage-dropdown">
                                            { props.translation_language !== null && props.translation_language !== 'nl-NL' &&
                                                <Link to={route_nl} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                    <div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_nl', props.debug)}
                                                </Link>
                                            }

                                            { props.translation_language !== null && props.translation_language !== 'en-US' &&
                                                <Link to={route_en} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                    <div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_en', props.debug)}
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>

                                { /* Multilanguage mobile */ }

                                { props.translation_language !== null && props.translation_language !== 'nl-NL' &&
                                    <div className="navbar-listitem d-block d-lg-none">
                                        <Link to={route_nl} className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_switch_nl', props.debug)}</Link>
                                    </div>
                                }

                                { props.translation_language !== null && props.translation_language !== 'en-US' &&
                                    <div className="navbar-listitem d-block d-lg-none">
                                        <Link to={route_en}  className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_switch_en', props.debug)}</Link>
                                    </div>
                                }

                                <div className="navbar-listitem">
                                    <HashLink smooth to="#recommendation" className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_ai', props.debug)}</HashLink>
                                </div>
                                
                                <div className="navbar-listitem">
                                    <HashLink smooth to="#profile" className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_profile', props.debug)}</HashLink>
                                </div>

                                <div className="navbar-listitem">
                                    <HashLink smooth to="#services" className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_services', props.debug)}</HashLink>
                                </div>

                                { /* Partnerlogin mobile */ }

                                <div className="d-block d-lg-none">
                                    {partnerlogin_items_mobile}
                                </div>
                                
                                { /* Partnerlogin deskop */ }
                                
                                <div className="d-none d-lg-block">
                                    <div className="navbar-item-partnerlogin-container">
                                        <div className="navbar-item-partnerlogin-item-container">
                                            <div className="navbar-item-partnerlogin-item">
                                                {UtilTranslate(props.translation_data, 'navigation_partnerlogin', props.debug)}
                                            </div>
                                        </div>

                                        <div className="navbar-item-partnerlogin-dropdown">
                                            {partnerlogin_items_desktop}
                                        </div>
                                    </div>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </section>
            <div className="navbar-section-spacing"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data
function SectionPrivacy(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'privacy'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/main/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="element-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'navigation_privacy', props.debug)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { content_data !== null &&
                                <div className="element-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data
function SectionProfile(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'profile'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/main/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="panel-section" id="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-header">{UtilTranslate(props.translation_data, 'content_profile_header', props.debug)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <picture>
                                <source srcSet="/image/profile.webp" type="image/webp" />
                                <source srcSet="/image/profile.jpg" type="image/jpg" /> 
                                <img src="/image/profile.jpg" alt="Guus Thissen" width="100%" loading="lazy" />
                            </picture>
                        </div>
                        <div className="col-12 col-md-8">
                            { /* XS, SM */ }
                            <div className="d-block d-md-none">
                                <div className="panel-profile-container-mobile">
                                    <div className="panel-profile-title-mobile">Guus Thissen</div>
                                    { /* <div className="panel-profile-subtitle-mobile">{UtilTranslate(props.translation_data, 'content_profile_title', props.debug)}</div> */ }
                                    { content_data !== null &&
                                        <div className="panel-text-quote" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                                    }
                                </div>
                            </div>
                            
                            { /* MD, LG, XL, XXL */ }
                            <div className="d-none d-md-block">
                                <div className="panel-profile-container-desktop">
                                    <div className="panel-profile-title-desktop">Guus Thissen</div>
                                    { /* <div className="panel-profile-subtitle-desktop">{UtilTranslate(props.translation_data, 'content_profile_title', props.debug)}</div> */ }
                                    { content_data !== null &&
                                        <div className="panel-text-quote" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

// Props: debug, translation_data, translation_language
function SectionServices(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [service_data, service_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/main/api/service', { params });

                    service_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }
            
            get();
        }
    }, [debug, ip_address, translation_language]);

    // Services
    let services = [];
    if (service_data !== null)
    {
        for (let i = 0; i < service_data.length; i++)
        {
            services.push(
                <div className="col-12 col-md-6" key={i}>
                    <div className="panel-services-title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service_data[i][0]) }}></div>

                    <div className="panel-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service_data[i][1], { ADD_ATTR: ['target'] })}}></div>
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <section className="panel-section-last" id="services"> 
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-header">{UtilTranslate(props.translation_data, 'content_services_header', props.debug)}</div>
                        </div>
                    </div>
                    <div className="row">
                        {services}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, translation_data, translation_language
function SectionSlideshow(props)
{
    // Slideshow data
    const [slideshow_current, slideshow_current_set] = useState(0);
    const [slideshow_previous, slideshow_previous_set] = useState(0);
    
    // Category, title text, type, interval color, background name, button, colortheme, tag type
    const slideshow = [
        [
            UtilTranslate(props.translation_data, 'slideshow_recommendation_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_recommendation_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_recommendation_text', props.debug),
            'black',
            'slideshow-background-machinelearning',
            [{
                buttontype: 'hash',
                to: 'recommendation',
                class: 'slideshow-button-primary',
                text: UtilTranslate(props.translation_data, 'slideshow_recommendation_button', props.debug)
            }],
            'primary',
            'h1'
        ],

        /*
        [
            UtilTranslate(props.translation_data, 'slideshow_recommendation_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_recommendation_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_recommendation_text', props.debug),
            'black',
            'slideshow-background-curves',
            [{
                buttontype: 'hash',
                to: 'recommendation',
                class: 'slideshow-button-primary',
                text: UtilTranslate(props.translation_data, 'slideshow_recommendation_button', props.debug)
            }],
            'primary',
            'h1'
        ],
        */
        
        /*
        [
            UtilTranslate(props.translation_data, 'slideshow_insight_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_insight_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_insight_text', props.debug),
            'black',
            'slideshow-background-machinelearning',
            [{
                buttontype: 'hash',
                to: 'services',
                class: 'slideshow-button-primary',
                text: UtilTranslate(props.translation_data, 'slideshow_insight_button', props.debug)
            }],
            'primary',
            'h3'
        ],
        */
        [
            UtilTranslate(props.translation_data, 'slideshow_customsoftware_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_customsoftware_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_customsoftware_text', props.debug),
            'black',
            'slideshow-background-customsoftware',
            [{
                buttontype: 'link',
                to: 'https://customsoftware.nl/'+props.translation_language,
                class: 'slideshow-button-customsoftware',
                text: 'CustomSoftware.nl'
            }],
            'customsoftware',
            'h3'
        ],
        [
            UtilTranslate(props.translation_data, 'slideshow_configurator_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_configurator_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_configurator_text', props.debug),
            'gray',
            'slideshow-background-configurator',
            [{
                buttontype: 'hash',
                to: 'contactform',
                class: 'slideshow-button-configurator',
                text: UtilTranslate(props.translation_data, 'navigation_contactform', props.debug)
            }],
            'configurator',
            'h3'
        ],
        [
            UtilTranslate(props.translation_data, 'slideshow_businessplatform_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_businessplatform_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_businessplatform_text', props.debug),
            'gray',
            'slideshow-background-businessplatform',
            [{
                buttontype: 'hash',
                to: 'contactform',
                class: 'slideshow-button-businessplatform',
                text: UtilTranslate(props.translation_data, 'slideshow_businessplatform_button', props.debug)
            }],
            'businessplatform',
            'h3'
        ],

        /*
        [
            UtilTranslate(props.translation_data, 'slideshow_visualization_category', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_visualization_title', props.debug),
            UtilTranslate(props.translation_data, 'slideshow_visualization_text', props.debug),
            'black',
            'slideshow-background-architecture',
            [{
                buttontype: 'link',
                to: 'https://architectuurvisualisatie.be/',
                class: 'slideshow-button-primary',
                text: 'ArchitectuurVisualisatie.be'
            }],
            'primary',
            'h3'
        ]*/ 
    ];

    // Slideshow interval
    let slideshow_interval = useRef(null);
    let slideshow_intervaltime = 15000; 

    if (props.debug === true)
    {
        slideshow_intervaltime = 150000;
    }
    
    useEffect(() => {
        slideshow_interval.current = setInterval(() => {
            clearInterval(slideshow_interval.current);
            
            let slideshow_new = (slideshow_current+1) % slideshow.length;
            slideshow_previous_set(slideshow_current);
            slideshow_current_set(slideshow_new);
        }, slideshow_intervaltime);
    }, [slideshow_current, slideshow_intervaltime, slideshow.length]);

    const setSlide = (slideshow_new) => {
        clearInterval(slideshow_interval.current);

        slideshow_previous_set(slideshow_current);
        slideshow_current_set(slideshow_new);
    };

    // Thumbnail slideshow
    const thumbnails_foreground = [];
    const thumbnails_background = [];
    for (let i = 0; i < slideshow.length; i++)
    {
        thumbnails_foreground.push(
            <div key={i} className="slideshow-thumbnail-flex" onClick={() => setSlide(i)}>
                <ElementSlideshowThumbnail
                    type={slideshow[slideshow_current][3]}
                    selected={i === slideshow_current}
                    category={slideshow[i][0]}
                    title={slideshow[i][1]}
                    text={slideshow[i][2]} 
                    color={slideshow[i][6]} />
            </div>
        );

        thumbnails_background.push(
            <div key={i} className="slideshow-thumbnail-flex">
                <ElementSlideshowThumbnail
                    type={slideshow[slideshow_current][3]}
                    selected={i === slideshow_current}
                    category={slideshow[i][0]}
                    title={slideshow[i][1]}
                    text={slideshow[i][2]} 
                    color={slideshow[i][6]} />
            </div>
        );
    }
    
    return (
        <section className="slideshow-section">
            { slideshow.length > 0 &&
                <React.Fragment>
                    <div className={'slideshow-container-overlay '+slideshow[slideshow_current][4]+' slideshow-fade-'+(1+slideshow_current)}>
                        <div className="container slideshow-content-container">
                            <ElementSlideshowContent
                                type={slideshow[slideshow_current][3]}
                                category={slideshow[slideshow_current][0]}
                                title={slideshow[slideshow_current][1]}
                                text={slideshow[slideshow_current][2]}
                                buttons={slideshow[slideshow_current][5]}
                                tag={slideshow[slideshow_current][7]} />
                        </div>
                        <div className={'slideshow-thumbnail-gradient-'+slideshow[slideshow_current][3]}>
                            <div className="container">
                                <div className="slideshow-thumbnail-container">
                                    {thumbnails_foreground}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'slideshow-container '+slideshow[slideshow_previous][4]}>
                        <div className="container slideshow-content-container">
                            <ElementSlideshowContent
                                type={slideshow[slideshow_previous][3]}
                                category={slideshow[slideshow_previous][0]}
                                title={slideshow[slideshow_previous][1]}
                                text={slideshow[slideshow_previous][2]}
                                buttons={slideshow[slideshow_previous][5]}
                                tag={slideshow[slideshow_previous][7]} />
                        </div>
                        <div className={'slideshow-thumbnail-gradient-'+slideshow[slideshow_current][3]}>
                            <div className="container">
                                <div className="slideshow-thumbnail-container">
                                    {thumbnails_background}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </section>
    );
}

// Props: debug, translation_data, translation_language
function SectionStatus(props)
{
    let number_server = 28;
    let status_apis = 4;

    let status_server_blocks = [];
    for (let i = 0; i < number_server; i++)
    {
        status_server_blocks.push(
            <div key={i} className="status-block-live"></div>
        );
    }

    let status_apis_blocks = [];
    for (let i = 0; i < status_apis; i++)
    {
        status_apis_blocks.push(
            <div key={i} className="status-block-live-wide"></div>
        );
    }
    
    return (
        <section className="status-section">
            <div className="container-sm">
                <div className="row">
                    <div className="col-12">
                        <div className="d-block d-lg-none">
                            <div className="status-container">
                                <div className="status-text-container">
                                    <div className="status-text">
                                        {UtilTranslate(props.translation_data, 'status_containers', props.debug)+' '+number_server+'/'+number_server}
                                    </div>
                                    <div className="status-block-container">
                                        <div className="status-block-live-wide"></div>
                                    </div>
                                </div>
                                <div className="status-text-container">
                                    <div className="status-text">
                                        {UtilTranslate(props.translation_data, 'status_apis', props.debug)+' '+status_apis+'/'+status_apis}
                                    </div>
                                    <div className="status-block-container">
                                        <div className="status-block-live-wide"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block">
                            <div className="status-container">
                                <div className="status-title">{UtilTranslate(props.translation_data, 'status_platform', props.debug)}</div>
                                <div className="status-chevron"></div>
                                <div className="status-text-container">
                                    <div className="status-text">
                                        {UtilTranslate(props.translation_data, 'status_containers', props.debug)+' '+number_server+'/'+number_server}
                                    </div>
                                    <div className="status-block-container">
                                        {status_server_blocks}
                                    </div>
                                </div>
                                <div className="status-text-container">
                                    <div className="status-text">
                                        {UtilTranslate(props.translation_data, 'status_apis', props.debug)+' '+status_apis+'/'+status_apis}
                                    </div>
                                    <div className="status-block-container">
                                        {status_apis_blocks}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export { SectionContentDataExpertise, SectionContentSalesRecommendationAI, SectionDisclaimer, SectionFooter, SectionForm, SectionNavigation, SectionPrivacy, SectionProfile, SectionServices, SectionSlideshow, SectionStatus };